import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import dataDogRumConfig from './plugins/datadog-rum'
import dataDogRumLogs from './plugins/datadog-logs'

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)

  return false
}

export const onClientEntry = () => {
  datadogRum.init(dataDogRumConfig)
  datadogRum.startSessionReplayRecording()
  datadogLogs.init(dataDogRumLogs)
  datadogRum.getInternalContext()
}

export const wrapRootElement = ({ element }) => (
  <FlagsmithProvider
    options={{
      environmentID: process.env.REACT_APP_FLAGSMITH_KEY ?? '',
    }}
    flagsmith={flagsmith}
  >
    {element}
  </FlagsmithProvider>
)
