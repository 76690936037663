import { getAppVersion } from 'src/utils/version'

export default {
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  version: getAppVersion(),
  service: process.env.REACT_APP_DATADOG_SERVICE,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
}
